import { computed, DestroyRef, effect, inject, Injectable, signal, untracked } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { jwtVerify } from 'jose';
import type { Observable } from 'rxjs';
import { skip } from 'rxjs';

import { UserService } from '@evc/platform';
import type { Maybe } from '@evc/web-components';
import { SharedConfigService } from '@shared/services/config/config.service';
import type { OrganizationConfig, OrganizationEnv } from '@app/types/config.type';

import type { MaybeTokenPayload, TokenPayload, TokenValidationError, TranslationParams } from './invitation.type';
import { TokenValidationErrors } from './invitation.type';

const DEFAULT_EXPIRATION_DELAY = 7 /* days */* 24 * 60 * 60;

@Injectable()
export class InvitationService {
  #destroyRef = inject(DestroyRef);
  #configService = inject(SharedConfigService<OrganizationEnv, OrganizationConfig>);
  #userService = inject(UserService);

  error = signal<Maybe<TokenValidationError>>(undefined);

  // * ---- TOKEN
  token = signal<Maybe<string>>(undefined);
  tokenPayload = signal<MaybeTokenPayload>({});
  waitTokenUpdate$:Observable<MaybeTokenPayload> = toObservable(this.tokenPayload).pipe(
    takeUntilDestroyed(this.#destroyRef),
    // skip 1st value (mandatory to define initial value)
    // but keep next (which could also be {} like initial value in case of invalid token)
    skip(1),
  );
  // we may skip on success validation but not onintro
  skipTokenExpirationValidation = false;
  #processTokenEffect = effect(() => {
    const token = this.token();
    untracked(() => this.#watchTokenAndUpdatePayload(token));
  });

  // store some info for translations (eg "hello {username}")
  translationParams = computed<TranslationParams>(() => ({
    username: this.#userService.profile()?.displayName,
    hostName: this.tokenPayload().hn ?? this.#hostName(),
    targetOrganizationName: this.tokenPayload().ton,
  }));

  // if token error - may store at least this info for display message
  #hostName=signal<Maybe<string>>(undefined);

  constructor() {
    this.#destroyRef.onDestroy(() => {
      this.#processTokenEffect.destroy();
    });
  }

  async processToken(token:string):Promise<TokenPayload> {
    const secretKey = this.#configService.get('JWT_INVITATION_SECRET');
    const secret = new TextEncoder().encode(secretKey);

    return jwtVerify<TokenPayload>(token, secret)
      .then(({ payload }: { payload: TokenPayload }) => payload)
      .then(this.#validateTokenExpirationDate.bind(this))
      .catch((error: { message: string; payload: TokenPayload; }) => {
        const filteredError:string = (() => {
          switch (error.message) {
            case TokenValidationErrors.EXPIRED:
              return error.message;
            default:
              return TokenValidationErrors.INVALID;
          }
        })();
        const thrownError = new Error(filteredError);
        if (error.payload) {
          Object.assign(thrownError, { payload: error.payload });
        }

        throw thrownError;
      });
  }

  #validateTokenExpirationDate(payload:TokenPayload):TokenPayload {
    if (this.skipTokenExpirationValidation) return payload;

    const { iat } = payload;
    const now = Math.floor(Date.now() / 1000);
    const expiredDate = now - this.#configService.get('JWT_INVITATION_DELAY', DEFAULT_EXPIRATION_DELAY);

    if (iat < expiredDate) {
      const error = new Error(TokenValidationErrors.EXPIRED);
      Object.assign(error, { payload }); // so can add extra info in error messages

      throw error;
    }

    return payload;
  }

  async #watchTokenAndUpdatePayload(token?:string):Promise<void> {
    const payload = !token
      ? {}
      : await this.processToken(token)
        .catch((error) => {
          this.error.set(error.message);

          // we'd like to display host name within error message
          if (error.payload?.hn) this.#hostName.set(error.payload.hn);

          return {} as TokenPayload;
        });

    this.tokenPayload.update(() => payload);
  }
}
