export type TokenPayload = {
  // issue at time
  iat: number
  // target organization id
  toid: string
  // target organization name
  ton: string
  // host id
  hid: string
  // host name
  hn: string
  // guest email
  ge: string
}

export enum TokenValidationErrors {
  INVALID = 'token-invalid',
  EXPIRED = 'token-expired',
  API = 'api-error',
}
export type TokenValidationError = `${TokenValidationErrors}`

export type MaybeTokenPayload = TokenPayload | Record<string, never>

export type TranslationParams = {
  username: string | undefined;
  hostName: string;
  targetOrganizationName: string;
}
