<div class="page">
  <header class="page__header">
    <h1
      class="page__title"
      [innerHtml]="'PAGE_INVITATION.'+step()+'.title' | translate:translationParams()"></h1>

    @if (step() === 'error') {
      <p
        class="page__description error"
        [innerHtml]="'PAGE_INVITATION.error.'+error() | translate:translationParams()"></p>
    }@else {
      <h2
        class="page__description"
        [innerHtml]="'PAGE_INVITATION.'+step()+'.description' | translate:translationParams()"></h2>
    }
  </header>

<!-- @if (debug) {
<pre class="page__content _debug" style="overflow-y: scroll; max-height: 60vh">
- translationParams : {{translationParams() | json}}
<hr>
- tokenPayload : {{tokenPayload() | json}}
<hr>
- userProfile : {{userProfile() | json}}
</pre>
} -->

  @if(['intro', 'success'].includes(step())){
    <footer class="page__actions">
      <evc-button
        class="action action__next action--center"
        type="primary"
        [attr.type]="'button'"
        (click)="onContinue($event)">
        {{ 'form.submit' | translate }}
      </evc-button>
    </footer>
  }
</div>

