import { ENVIRONMENT } from '@evc/platform';
import type { OrganizationEnv } from '@app/types/config.type';

export { ENVIRONMENT } from '@evc/platform';

export const environment: OrganizationEnv = {
  APP_NAME: 'organization',
  ENV: ENVIRONMENT.production,
  GREENFIELD: true,
  AUTH_FORCE_ORGANIZATION: false,
  JWT_INVITATION_DELAY: 3 /* days */ *24*60*60,
  // TODOD temporary - need to hide via gitvar (to fixe when api generate invit ok)
  JWT_INVITATION_SECRET: btoa('organizations'),
};
