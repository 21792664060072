import { JsonPipe } from '@angular/common';
import type { OnInit, Signal } from '@angular/core';
import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, signal, untracked } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { Observable } from 'rxjs';
import { of, switchMap, throwError } from 'rxjs';

import { AuthService, OrganizationsService, UserService } from '@evc/platform';
import type { Maybe } from '@evc/web-components';
import { NavButtonComponent } from '@evc/web-components';
import { SharedConfigService } from '@shared/services/config/config.service';
import { TranslatePipe } from '@shared/services/i18n/i18n.module';
import { InvitationService } from '@app/pages/invitation/invitation.service';
import type { OrganizationConfig, OrganizationEnv } from '@app/types/config.type';

import type { MaybeTokenPayload, TokenPayload, TokenValidationError, TranslationParams } from './invitation.type';
import { TokenValidationErrors } from './invitation.type';

export enum Steps {
  INIT = 'redirect-init',
  SUCCESS = 'success',
  ERROR = 'error',
}
export type Step = `${Steps}`

@Component({
  selector: 'evc-invitation',
  imports: [
    NavButtonComponent,
    TranslatePipe,
    JsonPipe,
  ],
  templateUrl: './invitation.page.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [InvitationService],
})
export class InvitationRedirectPageComponent implements OnInit {
  // TODO remove DEBUGS (when api ok) ---
  debug=false;
  get tokenPayload():Signal<TokenPayload | Record<string, never>> {
    return this.#invitationService.tokenPayload.asReadonly();
  }

  #authService = inject(AuthService);
  #destroyRef = inject(DestroyRef);
  #userService = inject(UserService);
  #invitationService = inject(InvitationService);
  #organizationsService = inject(OrganizationsService);
  #configService = inject(SharedConfigService<OrganizationEnv, OrganizationConfig>);

  step = signal<Step>(Steps.INIT);

  get translationParams():Signal<TranslationParams> {
    return this.#invitationService.translationParams;
  }

  get error():Signal<Maybe<TokenValidationError>> {
    return this.#invitationService.error.asReadonly();
  }
  #setErrorStepWhenError = effect(() => {
    const error = this.error();
    untracked(() => {
      if (error) this.step.set(Steps.ERROR);
    });
  });

  ngOnInit():void {
    this.debug = this.#configService.get('DEBUG_INVITATION_FLOW');

    // already validated before flow - (mini) risk to outdate between so just skip
    this.#invitationService.skipTokenExpirationValidation = true;

    this.#retrieveTokenPayloadFromState$()
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        switchMap((token:string) =>
          this.#validateAndDecodeToken$(token)
          .pipe(
            switchMap(() => of(token)),
          ),
        ),
        switchMap((token:string) => this.#organizationsService.inviteUserToOrganization$(token)),
      ).subscribe({
        next: () => {
          this.step.set(Steps.SUCCESS);
        },
        error: (error) => {
          this.#invitationService.error.set(error.message);
        },
      });
  }

  onContinue(event:Event):false {
    event.preventDefault();

    window.location.replace(this.#configService.get('uris').marketplace);

    return false;
  }

  #retrieveTokenPayloadFromState$():Observable<string> {
    const state = this.#authService.state() ?? '{}';
    const token = JSON.parse(state).token;

    return token && token.length > 10
      ? of(token)
      : throwError(() => new Error(TokenValidationErrors.INVALID));
  }

  #validateAndDecodeToken$(token:string):Observable<TokenPayload> {
    this.#invitationService.token.set(token);

    return this.#invitationService.waitTokenUpdate$.pipe(
      switchMap((tokenPayload:MaybeTokenPayload) => {
        const error = this.error();

        return error
          ? throwError(() => new Error(error))
          : of(tokenPayload as TokenPayload);
      }),
    );
  }
}
