import type { OrganizationEnv } from '@app/types/config.type';

import { ENVIRONMENT, environment as defaultEnv } from './environment.default';

export const environment: OrganizationEnv = {
  ...defaultEnv,
  ENV: ENVIRONMENT.development,
  AUTH_CLIENT_ID: '70e530c0-7600-4dbf-a0e4-2e14a90d2192',
  DEBUG_AUTH_FLOW: true,
  DEBUG_LOADING_FLOW: true,
};
