import { JsonPipe } from '@angular/common';
import type { OnInit, Signal } from '@angular/core';
import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, signal, untracked } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import type { RedirectRequest } from '@azure/msal-browser';
import type { Observable } from 'rxjs';
import { catchError, of } from 'rxjs';

import { AuthService, UserService } from '@evc/platform';
import type { Maybe } from '@evc/web-components';
import { NavButtonComponent } from '@evc/web-components';
import { SharedConfigService } from '@shared/services/config/config.service';
import { TranslatePipe } from '@shared/services/i18n/i18n.module';
import { InvitationService } from '@app/pages/invitation/invitation.service';
import type { OrganizationConfig, OrganizationEnv } from '@app/types/config.type';

import type { MaybeTokenPayload, TokenPayload, TokenValidationError, TranslationParams } from './invitation.type';

export enum Steps {
  INTRO = 'intro',
  ERROR = 'error',
}
export type Step = `${Steps}`

@Component({
  selector: 'evc-invitation',
  imports: [
    NavButtonComponent,
    TranslatePipe,
    JsonPipe,
  ],
  templateUrl: './invitation.page.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [InvitationService],
})
export class InvitationIntroPageComponent implements OnInit {
  // TODO remove DEBUGS (when api ok) ---
  debug=false;
  #configService = inject(SharedConfigService<OrganizationEnv, OrganizationConfig>);
  get tokenPayload():Signal<TokenPayload | Record<string, never>> {
    return this.#invitationService.tokenPayload.asReadonly();
  }

  #route = inject(ActivatedRoute);
  #authService = inject(AuthService);
  #destroyRef = inject(DestroyRef);
  #invitationService = inject(InvitationService);
  #userService = inject(UserService);

  step = signal<Step>(Steps.INTRO);

  get translationParams():Signal<TranslationParams> {
    return this.#invitationService.translationParams;
  }

  get error():Signal<Maybe<TokenValidationError>> {
    return this.#invitationService.error.asReadonly();
  }

  #setErrorStepWhenError = effect(() => {
    const error = this.error();
    untracked(() => {
      if (error) this.step.set(Steps.ERROR);
    });
  });

  ngOnInit(): void {
    this.debug = this.#configService.get('DEBUG_INVITATION_FLOW');

    this.#retrieveTokenPayloadFromRoute$()
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        catchError(() => of(false)),
      )
      .subscribe();
  }

  onContinue(event:Event):false {
    event.preventDefault();

    this.#loginRedirect();

    return false;
  }

  #retrieveTokenPayloadFromRoute$():Observable<MaybeTokenPayload> {
    const token:string = this.#route.snapshot.paramMap.get('token')!;
    this.#invitationService.token.set(token);

    return this.#invitationService.waitTokenUpdate$;
  }

  /**
   * use auth.login - but may help with good flows
   * - if not connected - start flow with create
   * - if connected using !== email, enfore new login flow
   *  - though he will be able to signin with differenmt account - we still suggest this one and avoid automatic match
   * - if connected with same one, let autodetection allowing in special cases to auto redirect to success (fresh valid token)
   *
   * We also store our token in the request state - will reuse after redirect
   * * note that MSAL does not allow wildcard redirect uris
   */
  #loginRedirect():void {
    const redirectUri = `${window?.location.origin}/invitation-success`;
    const { ge: loginHint } = this.#invitationService.tokenPayload();

    const request:Partial<RedirectRequest> = {
      state: JSON.stringify({ token: this.#invitationService.token() }),
      redirectUri,
      loginHint,
    };

    // enforce new login (expect small chance where connected user email match with invitation token + unexpired token)
    const isSameUser = (() => {
      const fromToken = this.tokenPayload().ge;
      const fromUser = this.#userService.profile()?.email;

      return fromToken && fromUser && fromToken === fromUser;
    })();
    if (!isSameUser) request.prompt = 'login';

    this.#authService.login(request);
  }
}
