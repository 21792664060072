<form action=""
  name="organization"
  class="page org-create-form"
  [formGroup]="form">
  <header class="page__header">
    @if (step() === 2) {
      <h1 class="page__title title--screening">{{ 'PAGE_CREATE.screening.title' | translate }}</h1>
      <h2 class="page__description page__description--centered">{{ 'PAGE_CREATE.screening.text' | translate }}</h2>
    }@else {
      <h1 class="page__title">{{ 'PAGE_CREATE.title' | translate }}</h1>
      <h2 class="page__subtitle">{{ 'PAGE_CREATE.subtitle.'+ ( step() === 0 ? 'profile' : 'address' ) | translate }}</h2>
    }
  </header>

  @switch(step()){
    @case(0) {
      <evc-form-infos #formInfo [submited]="submitedStep() >= 0" [formGroupParent]="form" />
    }
    @case(1) {
      <evc-address-form [formGroupParent]="form"
        [currentAddress]="currentAddress()"
        [showError]="isStepAddressSubmited()"
        parentComponentName="organization" />
    }
    @default {<!-- SCREENING handled in header/footer -->}
  }

  <div class="page__actions actions">
    @if (step() === 2) {
      <input
        (click)="onSubmitStep($event)"
        class="action action__submit action--center"
        type="submit"
        [value]="'PAGE_CREATE.screening.submit'|translate" />
    }@else {
      <!-- flex order reverse so enter trigger submit -->
      <input
        (click)="onSubmitStep($event)"
        class="action action__submit"
        type="submit"
        [value]="'form.submit'|translate" />

      <evc-button
        class="action action__cancel"
        type="tertiary"
        [attr.type]="'button'"
        (click)="onBack($event)">
          {{ 'form.'+ ( step() === 0 ? 'cancel' : 'back' )|translate }}
        </evc-button>
    }
  </div>
</form>