import type { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from '@env';

import { AuthFailureGuard, AuthLogoutGuard } from '@evc/platform';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { CreatePageComponent } from '@app/pages/create/create.page';
import { InvitationIntroPageComponent } from '@app/pages/invitation/invitation-intro.page';
import { InvitationRedirectPageComponent } from '@app/pages/invitation/invitation-redirect.page';

const DEBUG_DISABLE_GUARD_ON_DEV = environment.DEBUG_DISABLE_AUTH
  ? { canActivate: undefined }
  : {};

export const appRoutes: Route[] = [
  {
    path: 'logout',
    canActivate: [AuthLogoutGuard],
    component: PageNotFoundComponent,
    data: { logout: true },
  },
  {
    path: 'auth-fail',
    canActivate: [AuthFailureGuard],
    component: PageNotFoundComponent,
  },
  {
    path: '',
    component: CreatePageComponent,
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: 'invitation/:token',
    component: InvitationIntroPageComponent,
    data: { step: 'intro' },
  },
  {
    path: 'invitation-success',
    component: InvitationRedirectPageComponent,
    canActivate: [MsalGuard],
    data: { step: 'success' },
  },
  {
    path: '**',
    redirectTo: 'auth-fail',
  },
];
