import type { AfterViewInit, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, DestroyRef, inject, signal, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { AbstractControl, FormGroup } from '@angular/forms';
import { FormBuilder, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';

import type { ApiErrorItem, ApiOrganizationCreatePayload, UnprocessableError, ValidationError } from '@evc/platform';
import { OrganizationsService } from '@evc/platform';
import { NavButtonComponent } from '@evc/web-components';
import { AddressFormComponent } from '@shared/components/address-form/address-form.component';
import { EvcFormService } from '@shared/reactive-form/reactive-form.service';
import { EvcValidatorsService, patterns } from '@shared/reactive-form/validators/validator.service';
import { SharedConfigService } from '@shared/services/config/config.service';
import { I18nService, TranslatePipe } from '@shared/services/i18n/i18n.service';
import { StepInfosComponent } from '@app/components/step-infos/step-infos.component';
import type { CreateOrgFormData, CreateOrgFormModel } from '@app/pages/create/page-create.type';
import type { OrganizationConfig, OrganizationEnv } from '@app/types/config.type';

export enum CreateFlowStep {
  INFOS,
  ADDRESS,
  SCREENING,
}
@Component({
  standalone: true,
  selector: 'evc-form',
  templateUrl: 'create.page.html',
  styleUrl: 'create.page.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    TranslatePipe,
    NavButtonComponent,
    StepInfosComponent,
    AddressFormComponent,
  ],
  providers: [EvcFormService],
})
export class CreatePageComponent implements OnInit, AfterViewInit {
  @ViewChild(StepInfosComponent) private stepInfosComponent: StepInfosComponent<FormGroup<CreateOrgFormModel>> | undefined;
  @ViewChild(AddressFormComponent) private addressFormComponent: AddressFormComponent<FormGroup<CreateOrgFormModel>> | undefined;
  @ViewChild(FormGroupDirective) formDirective!: FormGroupDirective;

  #destroyRef = inject(DestroyRef);
  #changeDetectorRef= inject(ChangeDetectorRef);
  #formBuilder = inject(FormBuilder);
  #formService = inject(EvcFormService<CreateOrgFormData>);
  #validatorService = inject(EvcValidatorsService);
  readonly #configService= inject(SharedConfigService<OrganizationEnv, OrganizationConfig>);
  readonly #i18nService= inject(I18nService);
  readonly #organizationsService= inject(OrganizationsService);

  form!: FormGroup<CreateOrgFormModel>;
  step = signal(CreateFlowStep.INFOS);
  submited = this.#formService.submited;
  submitedStep = signal(-1);
  isStepAddressSubmited = computed(() => this.submitedStep() === CreateFlowStep.ADDRESS);
  currentAddress = computed(() => this.model()!.address);

  model = signal<CreateOrgFormData>({
    name: '',
    phone: '',
    address: {
      country: '',
      province: '',
      street: '',
      postalCode: '',
      city: '',
    },
  });
  ngOnInit(): void {
    const control = this.#formService.controlFactory(this.#formBuilder.nonNullable, this.model());

    this.form = this.#formBuilder.group<CreateOrgFormModel>({
      // - we create base profile fileds - so child form `infos` can simply use them
      name: control('name', [
        this.#validatorService.minLength(3),
        this.#validatorService.maxLength(25),
        this.#validatorService.pattern(patterns.alphanumericWithDash, 'noSpecialChars'),
      ]),
      phone: control('phone', [this.#validatorService.phone()]),
      // - we let the child form `address` creating it's own formGroup - it will be merged here
      // address: @see StepAddressComponent,
    });

    this.#formService.onUpdate$
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
    ).subscribe((data) => {
      this.model.update((model) => ({ ...model, ...data }));
    });
  }

  ngAfterViewInit(): void {
    this.#formService.bind(this.form, this.formDirective, this.#destroyRef);
  }

  onBack(event:Event):void {
    const step = this.step();
    const prevStep = step - 1;
    this.submitedStep.set(prevStep - 1);
    event.preventDefault();
    if (step > 0) {
      this.step.set(prevStep);

      return;
    }
    this.#redirectToApp('back');
  }

  /**
   * When click submit cta
   * - trigger validation
   * - if valid, go to next step
   * - if final step, will submit data to api
   */
  onSubmitStep(event:Event):void {
    event.preventDefault();
    const step = this.step();
    this.submitedStep.set(step);
    this.form.markAllAsTouched();
    if (!this.validate(step)) return;

    switch (step) {
      case CreateFlowStep.INFOS:
        this.#gotoAddressIfInfosValid();

        break;
      case CreateFlowStep.ADDRESS:
        this.#submitFormIfValid();

        break;
      case CreateFlowStep.SCREENING:
      default:
        this.#redirectToApp('screening');
    }
  }

  validate(step=CreateFlowStep.INFOS):boolean {
    const isValid = (() => {
      switch (step) {
        case CreateFlowStep.INFOS:
          return this.form.get('name')?.valid
            && this.form.get('phone')?.valid;
        case CreateFlowStep.ADDRESS:
          return this.form.get('address')?.valid;
        default:
          return true;
      }
    })();

    return isValid ?? false;
  }

  /** redirect to the other app
   * - may fail if no other orgs AND (TODO) otherApp need orgs
   * - or succeed if have other orgs OR (TODO) otherApp need no orgs
   * - add url param ?reason= auth-org--screening|auth-org--back
   */
  #redirectToApp(reason?:string):void {
    const uri = this.#configService.get('redirectUri') as string;
    const url = new URL(uri);
    if (reason) url.searchParams.append('reason', `auth-org--${reason}`);
    window.location.href = url.href;
  }

  #gotoAddressIfInfosValid() {
    this.#organizationsService.checkAlreadyExist(this.form.get('name')!.value)
    .then(() => {
      this.step.set(CreateFlowStep.ADDRESS);
    })
    .catch((error:ApiErrorItem) => {
      const errorKey = (error as UnprocessableError).type ?? 'exists';
      this.form.controls.name.setErrors({ [errorKey]: { message: error.message } });

      // TODO investigate change detection - markForCheck does not work so force detectChanges to child inputs
      this.#changeDetectorRef.markForCheck();
      this.stepInfosComponent?.inputComponents.forEach(input => input.changeDetectorRef.detectChanges());
    });
  }

  #submitFormIfValid() {
    const payload = this.form.getRawValue() as ApiOrganizationCreatePayload;
    // TODO use lib to reformat phone on display
    payload.phone = payload.phone.replace(/[^\d+]/g, '');

    this.#organizationsService.createOrganization(payload)
    .then(() => {
      this.step.set(CreateFlowStep.SCREENING);
    })
    .catch(async (errors:ApiErrorItem[] = []) => {
      const haveStep1Error = errors.some((error:ApiErrorItem) =>
        ['name', 'phone'].indexOf((error as ValidationError).propertyName?.toLowerCase() ?? '') > -1);

      if (haveStep1Error) {
        this.step.set(CreateFlowStep.INFOS);
        await new Promise(resolve => setTimeout(resolve, 0));
      }

      // TODO automatize routine
      errors.forEach(error => {
        const validationItem = error as ValidationError;
        const name = validationItem.propertyName;
        let control = this.form.controls;
        name.split('.').forEach((nameItem:string) => {
          // TODO fix api property names - must match payload keys so not Capitaliazed
          const nameItemMatchPayload = String(nameItem).charAt(0).toLowerCase() + String(nameItem).slice(1);
          // tmp solution will be refactored inside FormService
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let subControl = control[nameItemMatchPayload as keyof typeof control] as any;
          if (!subControl) return;
          if (subControl?.controls) subControl = subControl.controls;
          control = subControl;
        });
        (control as unknown as AbstractControl)?.setErrors?.({ [(error as ValidationError).code]: { message: error.message } });
      });

      // TODO investigate change detection - markForCheck does not work so force detectChanges to child inputs
      this.#changeDetectorRef.markForCheck();
      [
        ...this.stepInfosComponent?.inputComponents??[],
        ...this.addressFormComponent?.inputComponents??[],
      ].forEach(input => input.changeDetectorRef.detectChanges());
    });
  }
}
