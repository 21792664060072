import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, ViewChildren } from '@angular/core';
import { type FormGroup, ReactiveFormsModule } from '@angular/forms';

import { InputComponent } from '@evc/web-components';
import { EvcFormService } from '@shared/reactive-form/reactive-form.service';
import { TranslatePipe } from '@shared/services/i18n/i18n.service';
import type { CreateOrgFormModel } from '@app/pages/create/page-create.type';

type FormModelStepInfos = Pick<CreateOrgFormModel, 'name'|'phone'>;

@Component({
  selector: 'evc-form-infos',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    TranslatePipe,
    InputComponent,
  ],
  templateUrl: './step-infos.component.html',
  providers: [EvcFormService],
})
export class StepInfosComponent<TFormGroupParent extends FormGroup<FormModelStepInfos>> {
  @Input({ required: true }) submited = false;
  @Input({ required: true }) formGroupParent:TFormGroupParent = undefined as unknown as TFormGroupParent;
  @ViewChildren(InputComponent) inputComponents!: InputComponent[];
  changeDetectorRef = inject(ChangeDetectorRef);
}
